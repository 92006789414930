@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
}

.react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
    font-size: 13px;
    padding: 5px 15px;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "\25B6";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

nav.react-contextmenu.react-contextmenu--visible {
    z-index: 1000;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#site-logo {
  background: url(/static/media/logo.10c52449.png);
  background-size: cover;
  width: 90px;
  height: 90px;
}

#main-menu li {
  height: 90px;
  width: 90px;
  text-align: center;
  cursor: pointer;
}

#main-menu li a img {
  margin-top: -15px;
  width: 50px;
}

#main-menu li:nth-child(9) a img {
  margin-top: -10px;
  margin-left: -10px;
  width: 80px;
}

#main-menu li:last-child {
  padding-top: 20px;
}

#main-menu li:hover {
  background: #ededed;
}

.center-img {
  width:80px;
}

.form-group label {
  margin-right: 5px;
}

/*#root .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    pointer-events: none;
    opacity: 0.9;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled ,.ui.form .field.disabled>label, .ui.form .fields.disabled>label {

    opacity: 0.9;
}*/
.text-right {
    text-align: right;
}
.field.text-right {
  text-align: left;
}

.text-center .ui.input input {
    text-align: center;
}
.text-right .ui.input input {
    text-align: right;
}
div.ui.basic.label{
display: block;
}
div.ui.basic.label.text-center{
    text-align: center;
}
div.ui.basic.label.text-right{
    text-align: right;
}

right {
    text-align: right;
    display: block;
}

.ui.input.text-right input[type="text"] {
    text-align: right;
}

.hidden{
  display: none;
}

.text-cell{
  font-size: 14px;
  font-weight: lighter;  
  text-overflow: ellipsis;
  white-space: nowrap;
}


.ui.large.basic.label {
    min-height: 36px;
}
.react-datepicker__input-container {
  width: 100%;
}
#root .react-datepicker-wrapper{
  display: block;
}

.w50 {
  width: 50%;
}

.ui.small.form {
  font-size: .92857143rem;
  font-size: 13px;
}

.ui.dropdown>.text {
  /* display: inline-block; */
  /* transition: none; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.message-alert {
  margin: 5px;
  text-align: center;
  color: #21ba45;
  font-size: 17px;
  background: rgba(204, 249, 214, 0.42);
  border-radius: 15px;
  padding: 10px;
}

.text-cell{
  font-size: 12px;
}
#root form.small label {
  font-size: 13px;
  font-weight: bold;
}

.relative{
  position: relative;
}

.red{
  color: #db2828;
}

.orange{
  color: #f2711c;
}

.yellow{
  color: #fbbd08;
}

.green{
  color: #21ba45;
}

.pink{
  color: #e03997;
}

.blue{
  color: #2185d0;
}
.ui.attached.message {
  padding: 13px 16px;
  font-size: 13px;
}

div#view-print {
  font-size: 12px;
  padding: 45px;
  background: #757575;
  min-height: 450px;
  overflow-x: auto;
}

div#view-print #paperA4-portrait {
  background: #fff;
  border-radius: 0px;
  box-shadow: 1px 1px 5px #111;
  padding: 15px;
  width: 100%;
  min-height: 1200px;
}

div#view-print #paper75x13 {
  background: #fff;
  border-radius: 0px;
  padding-top: 0.02mm;
  width: 7.5cm;
  height: 1.3cm;
  margin-top: 0.08in;
}

div#view-print #paperbook {
  background: #fff;
  border-radius: 0px;
  padding-top: 0.02mm;
  width: 15cm;
  height: 17cm;
  margin-top: 0.08in;
}

#hide-text {
  width : 65px;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div#view-print #extra-wide {
  background: #fff;
  border-radius: 0px;
  box-shadow: 1px 1px 5px #111;
  padding: 15px;
  width: 2300px;
  min-height: 1200px;
}
div#view-print #extra-wide2 {
  background: #fff;
  border-radius: 0px;
  box-shadow: 1px 1px 5px #111;
  padding: 15px;
  width: 2000px;
  min-height: 1200px;
}
.react-datepicker-wrapper {
  width: 100%;
}


.td_zone{
  border-right: 2px solid #f00;
}


.ui.basic.nobarder.table tbody tr,.ui.basic.nobarder.table tr td{
  border: none;
}

.text-center {
  text-align: center;
}

body {
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  color: rgba(49, 49, 49, 0.87);
  /* font-family: sans-serif; */
}

img.ui.image.logo-login {
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  width: 57px;
}

.ui.segment.segment-login {
  padding: 13px 39px;
  width: 100%;
  padding-bottom: 45px;
  padding-top: 26px;
  box-shadow: 0px 0px 8px #c3c3c3;
  border: none;
}
a {
  color: #21282E;
  text-decoration: none;
  cursor: pointer;
}
div#root-content {  
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  width: 100%;
  padding: 3%;
  margin: auto;
  overflow-x: hidden;
  padding-top: 55px;
  z-index: 1;
  overflow-x: auto;

}
div#root-content > div {  
  width: 100%;
  min-width: 1200px;
  
}
div#root-content-redeem > div {  
  width: 100%;  
}
div#root-content-redeem {
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  width: 100%;
  padding: 25px;
  margin: auto;
  overflow-x: hidden;
  padding-top: 55px;
  z-index: 1;
  overflow-x: auto;
}
.input-redeem > input[type=text], #inputSearchNameRedeem > input[type=text], #inputSearchNameRedeem > .text {
  font-family: 'Kanit' !important;
  font-size: 23px !important;
}
.imgLogoTextAAGold {
  margin: auto; 
  padding: 35px 25px 15px 25px; 
  border-radius: 0px 0px 20px 20px; 
  background-color: #fef2d4 !important;
  width: 100%;
}
.labelAmountRedeem, .formSearchCustomerRedeem .field>label {
  color: rgba(0,0,0,0.6) !important;
  font-weight: 500 !important;
  font-size: 23px !important;
}
#inputSearchNameRedeem.selection.dropdown .menu>.item {
  font-size: 23px;
}


.menu-top > .ui.pointing.secondary.menu {
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  border: none;
  box-shadow: 1px 1px 2px #797979;
}

.ui.secondary.vertical.menu-left.menu {
  width: 100%;
}

.fixedDataTableLayout_main.public_fixedDataTable_main {
  border: none;
}

.fixedDataTableCellLayout_main.public_fixedDataTableCell_main {
  border: none;
  /* background: #fff; */
}

.public_fixedDataTable_header, .public_fixedDataTable_header .public_fixedDataTableCell_main{
  background: #565656;
  color: #fff;
}

button.ui.button {
  background: #e2e2e2;
}

.fixedDataTableLayout_main.public_fixedDataTable_main {
  border: 1px solid #eaeaea;
  /* border-radius: 3px; */
  overflow: hidden;
}

.ui.form input.text-right {
  text-align: right;
}

.form-pos {
  position: fixed;
  top: 0;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 15px;
  border: 11px solid rgba(34, 36, 38, 0.3);
}

.react-contexify {
  z-index: 101;
}

.ui.search>.results {
  max-height: 300px;
  overflow-y: auto;
}
.button.btn-add-customer {
  margin-top: 24px;
}

.option-add > div.ui.fluid.dropdown {
  display: block;
  width: 90%;
}

.option-add > button{
  float: right;
}

div#content-body > div {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 5%;
  background: #fff;
  width: 100%; */
  /* opacity: 0.5; */
}

.ui.fluid.pointing.secondary.vertical.menu-report.menu {
  /* position: fixed; */
  left: 22px;
  /* height: 100%; */
  overflow-y: auto;
  max-width: 275px;
  bottom: 20px;
  top: 64px;
  overflow-x: hidden;
  box-shadow: 0px 0px 2px #737373;
}
.table-border-none{
  border:0px !important;
}
.table-header-border{
  border: 1px solid rgba(34,36,38,.1) !important;
  padding: 0.92857143em 0.78571429em !important;
}
.table-header-border-t-b{
  border-top: 1px solid rgba(34,36,38,.1) !important;
  border-bottom: 1px solid rgba(34,36,38,.1) !important;
  padding: 0.92857143em 0.78571429em !important;
}
.table-row-hide{
  border: 0px !important;
  padding: 0.92857143em 0.78571429em !important;
}
.scrolling.content {
  min-height: 430px;
}

.tabled-customer i.icon {
  font-size: 16px;
  color: #696969;
}

.cell_red {
  color: #afafaf;
  font-style: italic;
  text-decoration: line-through;
}

div#root-form-bill {
  background: #fff;
  border-top: 1px solid #ccc;
  margin: 0;
  padding-top: 18px;
}

.ui.labeled.icon.menu a.item {
  padding: 6px;
  font-size: 12px;
}

.ui.modal>.content {
  min-height: 500px;
}

.ui.header>.icon {
  display: inline-block;
  opacity: 1;
  font-size: 1.5em;
  padding-top: 0;
  vertical-align: middle;
}

/* .ui.fullscreen.modal {
  width: 100%!important;
  left: 0%!important;
  margin: 0em auto;
}

.modals.dimmer .ui.scrolling.modal {
  position: static!important;
  margin: 0 auto!important;
} */

.two.wide.field.text-right.grey input[type="text"] {
  color: grey;
}

.two.wide.field.text-right.green input[type="text"] {
  color: green;
}

.two.wide.field.text-right.red input[type="text"] {
  color: red;
}

.two.wide.field.text-right.yellow input[type="text"] {
  color: #8c8c07;
}

.ui.modal.transition .content {
  min-height: auto;
}

.ui.primary.button, .ui.primary.buttons .button {
  background-color: #2ea8ff;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover {
  background-color: #42a1e8;
  color: #fff;
  text-shadow: none;
}

.ui.input.text-right input[type="number"] {
  text-align: right;
}

.ui.basic.button, .ui.basic.buttons .button {
  background: rgba(204, 204, 204, 0.31) none!important;
  color: rgb(0, 0, 0)!important;  
}

.public_fixedDataTable_header .public_fixedDataTableCell_main  .public_fixedDataTableCell_cellContent a {
  color: #fff;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main .public_fixedDataTableCell_cellContent a:hover {
  color: #ccc;
}

.ScrollbarLayout_main.ScrollbarLayout_mainHorizontal.public_Scrollbar_main.public_Scrollbar_mainOpaque {
  background: #f5f5f5;
}
.fixedDataTableLayout_main.public_fixedDataTable_main {
  background: #f5f5f5;
}

.public_fixedDataTable_main, .public_fixedDataTable_header, .public_fixedDataTable_hasBottomBorder {
  border-bottom: none;
}

.modal .ui.basic.button, .ui.basic.buttons .button {
  background: rgba(204, 204, 204, 0.31) none!important;
  color: rgb(0, 0, 0)!important;
  margin-top: 13px;
  margin-right: 13px;
}

.ui.search.dropdown>.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.input-search-customer .btn-add-customer {
  margin: 0;
  display: inline-block;
  width: 19%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0;
  height: 37px;
  margin-left: -1px;
  line-height: 1.5em;
}

.input-search-customer .btn-add-customer-saving {
  margin: 0;
  display: inline-block;
  width: 19%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0;
  height: 37px;
  margin-left: -1px;
  line-height: 1.2em;
}

.input-search-customer .field {
  width: 80%;
  display: inline-block;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.input-search-customer .field .ui.fluid.search.selection.dropdown {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

body .ui.green.huge.basic.label {
  background-color: #fff!important;
}
body  ul.react-datepicker__time-list {
  padding: 0;
  margin: 0;
}

.ui.modal>.header:not(.ui){
  font-size: 1.1em;
  padding: 0.9em;
}
.ui.modal>.actions {
  padding: .7em;
}

.ui.attached.message {
  padding: 4px 8px;
  font-size: 13px;
}
button.ui.basic.circular.icon.right.floated.button {
  font-size: 9px;
}
.fixedDataTableCellLayout_wrap1 {
  display: table;
  width: 100%;
}
div#table_w {
  margin-bottom: 15px;
}
.field div.ui.basic.label {
  background-color: #fff!important;
}
a.link {
  color: #2a6c9e;
}
#root .ui .menu-report .menu>a.item.item {
  padding: 0.6em 2em;
}
#root .ui .menu-report .header{
  padding: 1em;
}
.fixedDataTableLayout_main.public_fixedDataTable_main {
  width: 100%!important;
}
.cell {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  display: block;
  padding: 8px;
  width: 100%;
}

.cell-overflow {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pull-left {
  float: left;
  margin-right: 15px;
}
.pull-right {
  float: right;
  /* margin-left: 15px; */
  padding-top: 3px;
  min-width: 125px;
  font-size: 11px;
} 

.dropdown-icon i.icon,i.icons {
  font-size: 10px!important;
}

.ui.header .ui.fluid.dropdown {
  display: block;
  width: 100%;
  min-width: 0;
  padding: 11px;
  font-size: 13px;
  min-width: 200px;
  font-weight: lighter;
}

.fixedDataTableLayout_rowsContainer {

  position: relative;
}

.fixedDataTableRowLayout_rowWrapper {
  position: absolute;
  top: 0;
  max-width: 100%;
}

.public_fixedDataTable_header, .public_fixedDataTable_header .public_fixedDataTableCell_main {
  background: #565656;
  color: #fff;
}

.ui.fullscreen.modal {
  width: 100%!important;
  left: 0!important;
  margin: 1em auto;
}
body .ui.pointing.secondary.menu i.icon, i.icons {
  font-size: 15px!important;
}
body .ui.pointing.secondary.menu i.dropdown.icon {
  font-size: 10px!important;
}div#view-print #paperA5-portrait {
  background: #fff;
  border-radius: 0px;
  box-shadow: 1px 1px 5px #111;
  padding: 15px;
  width: 100%;
}
.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
  opacity: .745;
}

.ui.form .field>.selection.dropdown {
  word-break: break-word;
}

.ui.selection.active.dropdown:hover {
  word-break: break-word;
}

.ml-1 {
  margin-left: 1em!important;
}

.top-menubar.ui.secondary.pointing.menu .item {
  padding-right: 4px;
  padding-left: 10px;
}

.ui.icon.button, .ui.icon.buttons .button {
  padding: .78571429em .78571429em .8571429em;
}

.dropdown-icon-saving {
  padding-top: 8px !important;
  padding-bottom: 9px !important;
  line-height: 14px !important;
}

.react-pdf__Page__canvas{
  margin : auto;
  width: 80% !important;
  height: 80% !important;
}
div#label-version {
  margin-top: -20px;
  margin-bottom: 1px;
  text-align: center;
  background: red;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
}

#alertIcon {
  padding-top: 0px !important;
  margin-right: 10px;
}

#alertIcon div.label{
  padding: 2px 4px !important;
  margin: 2px 0px 0px -17px !important;
}

#hidePassword {
  -webkit-text-security: disc;
}

@media (max-width: 767px) {
  .checkbox-lease-across-branch {
    position: absolute;
    bottom: 36px;
  }
  .btn-search-reset {
    margin-top: 20px !important;
  }
}
.modal-table-print table .borLder{
  border-left : 1px solid rgba(34,36,38,.1) !important;
}
.box-login {
   
  width: 100%;
  margin: auto;
  display: block;
  margin-top: 3%;
  margin-bottom: 5%;
  max-width: 400px;
}

.form_size{
  width: 400px;
}

.hidden{
  display: none;
}
.react-datepicker-popper {
    z-index: 5;
}

.cell-time-click:hover{
  cursor: pointer;
}
.fixedDataTableRowLayout_rowWrapper:hover{
  background-color: #eee;
}
.public_fixedDataTableCell_cellContent {
  padding: 5px;
  font-size: 13px;
}
.ui.pointing.secondary.menu{
  background-color: #fff;
}

.ui.pointing.secondary.menu i.icon, i.icons{
  font-size: 20px;
}

.red{
  color: red;
}

