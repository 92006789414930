.box-login {
   
  width: 100%;
  margin: auto;
  display: block;
  margin-top: 3%;
  margin-bottom: 5%;
  max-width: 400px;
}

.form_size{
  width: 400px;
}

.hidden{
  display: none;
}
.react-datepicker-popper {
    z-index: 5;
}

.cell-time-click:hover{
  cursor: pointer;
}
.fixedDataTableRowLayout_rowWrapper:hover{
  background-color: #eee;
}
.public_fixedDataTableCell_cellContent {
  padding: 5px;
  font-size: 13px;
}
.ui.pointing.secondary.menu{
  background-color: #fff;
}

.ui.pointing.secondary.menu i.icon, i.icons{
  font-size: 20px;
}

.red{
  color: red;
}
